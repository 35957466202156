<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card style="width: 500px; max-width: 70vw">
      <q-card-section class="row items-center justify-between">
        <h6>Cursos Concluídos</h6>
        <q-btn icon="close" flat round dense @click="$emit('closeDialog')" />
      </q-card-section>
      <hr />
      <q-card-section >

        <div class="column items-center scroll-y no-wrap"  style=" height: 300px;" ref="coursesRef">
          <q-scroll-observer debounce="400" @scroll="(e) => onScroll(e)" />
          <div
              class="full-width q-mb-sm "
              v-for="course in CoursesConcluded"
              :key="course.title"
          >
            <CardLastCourses :title="course?.title" :idCourse="course?.id" />
          </div>
        </div>


      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
//----COMPONENTS
import CardLastCourses from "@/components/profile/cards/CardLastCourses.vue";

import {reactive, ref, watch} from "vue";
import TrailDataServices from "@/services/TrailDataServices";
import {useQuasar} from "quasar";

export default {
  name: "DialogMoreCourses",
  components: {
    CardLastCourses,
  },
  props: {
    showDialog: Boolean,
    courses: Array,
  },
  emits: ["closeDialog"],
  setup(props) {
    const trailDataServices = new TrailDataServices();
    const coursesRef = ref(null)
    const paginateState = reactive({
      pageNumber: 1,
      pageSize: 5,
    })
    const $q = useQuasar()


    let isShowDialog = ref(props.showDialog);
    let CoursesConcluded = ref([]);


    async function fetchCourses() {
       const response = await GetAllFinishedCourses()
      if (!response){
        $q.notify({type:"warning", message:"Não há mais cursos finalizados!", position: "top"});
        return
      }
      CoursesConcluded.value = CoursesConcluded.value.concat(response)
    }

   async function GetAllFinishedCourses(){
      const response = await trailDataServices.getFinished(paginateState.pageNumber, paginateState.pageSize);
      paginateState.pageNumber += 1
      return response.data.data
    }

    async function onScroll(scroll) {
      const myElement = coursesRef.value;
      const topPos = myElement.scrollHeight - myElement.clientHeight - 1;

      if (topPos < scroll.position.top && scroll.directionChanged === false) {
        await loadMoreCourses();
      }
    }

    async function loadMoreCourses() {
      await fetchCourses();
    }

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;

        if (newState){
          fetchCourses()
        }else{
          paginateState.pageNumber = 1
        }
      }
    );




    return {
      isShowDialog,
      CoursesConcluded,
      onScroll,
      coursesRef
    };
  },
};
</script>